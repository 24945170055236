import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Box } from "@mui/material";
import { LocaleContext, LocalizedLink as Link } from "gatsby-theme-i18n";
import { useIntl } from "react-intl";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

import { Footer, LanguageSelector } from "~/components";

// styles
const pageStyles = {
  color: "#232129",
  padding: 0,
};

// markup
export default function IndexPage({ data, location }) {
  const { formatMessage } = useIntl();
  const locale = React.useContext(LocaleContext);
  const t = React.useCallback((id) => formatMessage({ id }), [formatMessage]);

  const getImageFromQuery = React.useCallback(
    (queryName) => getImage(data[queryName]),
    [data]
  );

  const setRouterLink = React.useCallback(
    (child) => {
      const { category, model } = data.router;

      const label = t(`product-side-router-${model.toLowerCase()}`);

      return (
        <Link
          to={`/products/${category}/${model}/`.toLowerCase()}
          aria-label={label}
        >
          {child}
        </Link>
      );
    },
    [data, t]
  );

  const setCoatingLink = React.useCallback(
    (modelName, child) => {
      const { category, model } = data.coating.nodes.find(
        (node) => node.model === modelName
      );

      const label = t(`product-side-coating-${model.toLowerCase()}`);

      return (
        <Link
          to={`/products/${category}/${model}/`.toLowerCase()}
          aria-label={label}
        >
          {child}
        </Link>
      );
    },
    [data, t]
  );

  return (
    <>
      <GatsbySeo
        title={
          locale === "ko"
            ? "(주)임펙 엔터프라이즈"
            : "IMPEC Enterprise Co., Ltd."
        }
        description={t("seo-index-description")}
      />
      <JsonLd
        json={{
          "@context": "http://schema.org",
          "@type": "Person",
          name: "IMPEC Enterprise Official Pages",
          url: "http://www.impec.com",
          sameAs: ["https://blog.naver.com/impec_enterprise"],
        }}
      />
      <main style={pageStyles}>
        <Box
          style={{
            display: "grid",
            placeItems: "center",
            minHeight: "calc(100vh - 256px)",
            paddingBottom: "40px",
            background:
              "linear-gradient(0deg, rgba(242,242,247,1) 0%, rgba(227,228,232,1) 26%, rgba(217,217,222,1) 26.5%, rgba(242,242,246,1) 100%)",
          }}
        >
          <Box style={{ maxWidth: "1220px" }}>
            <Box
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "10px",
                marginBottom: "55px",
                columnGap: "20px",
              }}
            >
              <StaticImage
                src="../images/logo_color.png"
                quality={100}
                alt={"IMPEC's Logo"}
                objectFit="contain"
              />
              <LanguageSelector location={location} />
            </Box>
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: "40px",
                rowGap: "30px",
              }}
            >
              <Box style={{ position: "relative" }}>
                {setCoatingLink(
                  "ACS-600",
                  <Box
                    style={{
                      width: "30%",
                      height: "100%",
                      position: "absolute",
                      zIndex: 1,
                    }}
                  />
                )}
                {setCoatingLink(
                  "COS-220",
                  <Box
                    style={{
                      width: "35%",
                      height: "100%",
                      marginInlineStart: "30%",
                      position: "absolute",
                      zIndex: 1,
                    }}
                  />
                )}
                {setCoatingLink(
                  "CIS-700",
                  <Box
                    style={{
                      width: "35%",
                      height: "100%",
                      marginInlineStart: "65%",
                      position: "absolute",
                      zIndex: 1,
                    }}
                  />
                )}
                <GatsbyImage
                  image={getImageFromQuery("imageCoatingLine")}
                  alt="IMPEC Coating Line Solutions"
                  style={{
                    maxWidth: "100%",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Box>
              {setRouterLink(
                <GatsbyImage
                  image={getImageFromQuery("imageRouter")}
                  alt="IMPEC Router"
                  style={{
                    maxWidth: "100%",
                    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </main>
      <Footer style={{ height: 156 }} />
    </>
  );
}

export const query = graphql`
  query {
    router: product(model: { eq: "PRS-3100" }) {
      category
      model
    }

    coating: allProduct(
      filter: { model: { in: ["ACS-600", "COS-220", "CIS-700"] } }
    ) {
      nodes {
        category
        model
      }
    }

    imageCoatingLine: file(name: { eq: "coating_line" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }

    imageRouter: file(name: { eq: "router" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
  }
`;
